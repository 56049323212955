import { Grid, Link } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { withStyles } from 'src/styles/utils';

import { i18n, templateValues } from './LoginSideBar.i18n';
import { styles } from './LoginSideBar.styles';
import { LoginSideBarProps } from './LoginSideBar.types';

const p = (chunks: React.ReactNode) => <p>{chunks}</p>;
const h1 = (chunks: React.ReactNode) => <h1>{chunks}</h1>;
const a1 = (chunks: React.ReactNode) => (
    <Link
        rel="noopener"
        target="_blank"
        href={templateValues.learnMoreUrl}
        automation-id={templateValues.learnMoreUrl}
    >
        {chunks}
    </Link>
);
const a2 = (chunks: React.ReactNode) => (
    <Link
        rel="noopener"
        target="_blank"
        href={templateValues.onboardingUrl}
        automation-id={templateValues.onboardingUrl}
    >
        {chunks}
    </Link>
);
const a3 = (chunks: React.ReactNode) => (
    <Link
        rel="noopener"
        target="_blank"
        href={templateValues.supportUrl}
        automation-id={templateValues.supportUrl}
    >
        {chunks}
    </Link>
);
const a4 = (chunks: React.ReactNode) => (
    <Link
        rel="noopener"
        target="_blank"
        href={templateValues.trainingUrl}
        automation-id={templateValues.trainingUrl}
    >
        {chunks}
    </Link>
);
const a5 = (chunks: React.ReactNode) => (
    <Link
        rel="noopener"
        target="_blank"
        href={templateValues.supportRequestUrl}
        automation-id={templateValues.supportRequestUrl}
    >
        {chunks}
    </Link>
);
export function LoginSideBarBase(props: LoginSideBarProps) {
    const { classes } = props;
    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.body}>
                <FormattedMessage
                    id={i18n.body.id}
                    defaultMessage={i18n.body.defaultMessage}
                    values={{
                        p,
                        h1,
                        a1,
                        a2,
                        a3,
                        a4,
                        a5,
                    }}
                />
            </Grid>
        </Grid>
    );
}

export const LoginSideBar = withStyles(styles)(LoginSideBarBase);
